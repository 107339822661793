import React from "react";
import PropTypes from "prop-types";
import {Button, Breadcrumbs, Breadcrumb} from "react-rainbow-components";
import styled from "@emotion/styled";
import throttle from "lodash.throttle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


// Components
import settings from "./settings";
import Fullpage from "./components/Fullpage";
import AButton from "./components/AButton";
import Dashbord from "./components/Dashbord";


// Styles
import "./App.scss";


// Fonts
import {library} from "@fortawesome/fontawesome-svg-core";
import {faSun, faHomeLgAlt, faBars, faChartBar, faThList, faPlug} from "@fortawesome/pro-solid-svg-icons";
//import {faSun, faHomeLgAlt, faBars, faChartBar, faThList, faPlug} from "@fortawesome/pro-light-svg-icons";

library.add([faSun, faHomeLgAlt, faBars, faChartBar, faThList, faPlug]);


// App
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: 0,
        };

        this.bound_watchResizing = throttle(this.watchResizing.bind(this), 500);
    }

    componentDidMount() {
        window.addEventListener('resize', this.bound_watchResizing, true);
        this.updateAppMobileState();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.bound_watchResizing, true);
    }

    watchResizing() {
        this.updateAppMobileState();
    }

    updateAppMobileState() {
        if (typeof window !== "undefined") {
            const clientWidth = window.innerWidth;

            if (clientWidth !== undefined) {
                this.setState({windowWidth: clientWidth});
                console.log(this.state.windowWidth, "windowWidth");
            }
        }
    }

    render() {
        console.log(settings.breakpoints);
        return (
            <div className="App">
                <div className="app-container">
                    <div className="menu-container">
                        <Menu/>
                    </div>
                    <div className="content-container">
                        <Fullpage>
                            <PageElectricity id="electricity">
                                <Dashbord type="electricity"/>
                            </PageElectricity>
                            <PageSolarPower id="solarpower">
                                <Dashbord type="solarPower"/>
                            </PageSolarPower>
                        </Fullpage>
                    </div>
                </div>
            </div>
        );
    }
}


// メニュー
const Menu = (props) => {
    return (
        <div className="menu-bar">
            <ol className="menu-list">
                <li>
                    <AButton href="#electricity" height="60px" width="60px" circle={true}>
                        <FontAwesomeIcon icon="plug"/>
                    </AButton>
                </li>
                <li>
                    <AButton href="#solarpower" height="60px" width="60px" circle={true}>
                        <FontAwesomeIcon icon="sun"/>
                    </AButton>
                </li>
            </ol>
        </div>
    )
};


// 電気ページ
const PageElectricity = (props) => {
    return (
        <div className="section">
            {props.children}
        </div>
    )
};


// 太陽光発電ページ
const PageSolarPower = (props) => {
    return (
        <div className="section">
            {props.children}
        </div>
    )
};


export default App;
