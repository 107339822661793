import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';


const AButton = (props) => {
    const style = {borderRadius: props.circle ? '500px' : '20px'};

    return (
        <Button
            href={props.href}
            style={style}
            data-anchor={props.href}
            height={props.height}
            width={props.width}
            bgColor={props.bgColor}
            textColor={props.textColor}
            bgColorHover={props.bgColorHover}
            textColorHover={props.textColorHover}
            dropShadow={props.dropShadow}
        >
            {props.children}
        </Button>
    )
};

AButton.propTypes = {
    href: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    bgColorHover: PropTypes.string,
    textColorHover: PropTypes.string,
    dropShadow: PropTypes.bool,
    circle: PropTypes.bool,
};

AButton.defaultProps = {
    href: '#',
    height: '100%',
    width: '100%',
    bgColor: '#ffffff',
    textColor: '#333333',
    bgColorHover: '#00b7f5',
    textColorHover: '#ffffff',
    dropShadow: true,
    circle: false,
};


const Button = styled.a`
    height: ${props => props.height};
    width: ${props => props.width};
    
    color: ${props => props.textColor};
    font-size: 2rem;
    
    background-color: ${props => props.bgColor}; 

    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    ${props => props.dropShadow && `
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    `};

    &:hover {
        color: ${props => props.textColorHover};
        background-color: ${props => props.bgColorHover};
    }
    
    &:active {
        ${props => props.dropShadow && `
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
        `};
    }
    
    &.active {
        color: ${props => props.textColorHover};
        background-color: ${props => props.bgColorHover};   
    }
`;

export default AButton;