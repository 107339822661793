import React from 'react';
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {Button, ButtonGroup, Chart, Dataset} from 'react-rainbow-components';


// MockData
import {electricityDatasets} from "../../data/electricity";
import {powerGeneration} from "../../data/powerGeneration";


class BarChart extends React.Component {
    constructor(props) {
        super(props);
        this.titles = ['Data-Blue', 'Data-Purple', 'Data-Dark'];
        this.colors = ['#01b6f5', '#663398', '#061c3f'];
        this.months = ['7月', '8月', '9月', '10月', '11月', '12月'];
        this.state = {
            labels: [
                "0時", "1時", "2時", "3時", "4時", "5時", "6時", "7時", "8時", "9時", "10時", "11時",
                "12時", "13時", "14時", "15時", "16時", "17時", "18時", "19時", "20時", "21時", "22時", "23時"
            ],
            datasets: this.setData(),
        };
    }

    setData() {
        switch (this.props.type) {
            case 'electricity':
                return electricityDatasets;
            case 'solarPower':
                return powerGeneration;
            default:
                return [];
        }
    }

    addDataset() {
        const {labels, datasets} = this.state;
        const newValues = labels.map(label => Math.round(Math.random() * 100));
        const newDatasets = datasets.concat({
            title: this.titles.shift(),
            values: newValues,
            backgroundColor: this.colors.shift(),
        });
        this.setState({datasets: newDatasets});
    }

    removeDataset() {
        const {datasets} = this.state;
        const dataset = datasets[datasets.length - 1];
        this.titles.unshift(dataset.title);
        this.colors.unshift(dataset.backgroundColor);
        const newDatasets = datasets.filter(d => d.title !== dataset.title);
        this.setState({datasets: newDatasets});
    }

    addMonth() {
        const {labels, datasets} = this.state;
        const newlabels = labels.concat(this.months.shift());
        const newDatasets = datasets.map(dataset => {
            const {values, ...rest} = dataset;
            const newValues = values.concat(Math.round(Math.random() * 100));
            return {
                ...rest,
                values: newValues,
            };
        });
        this.setState({labels: newlabels, datasets: newDatasets});
    }

    removeMonth() {
        const {labels, datasets} = this.state;
        const label = labels[labels.length - 1];
        this.months.unshift(label);
        const newLabels = labels.filter(l => l !== label);
        const newDatasets = datasets.map(dataset => {
            const {values, ...rest} = dataset;
            const newValues = values.slice(0, values.length - 1);
            return {
                ...rest,
                values: newValues,
            };
        });
        this.setState({labels: newLabels, datasets: newDatasets});
    }

    renderDatasets() {
        const {datasets} = this.state;
        return datasets.map(({title, values, backgroundColor}) => (
            <Dataset key={title} title={title} values={values} backgroundColor={backgroundColor}/>
        ));
    }

    render() {
        const {labels, datasets} = this.state;

        const noMoreTitles = this.titles.length === 0;
        const noMoreDatasets = datasets.length === 0;
        const noMoreMonths = this.months.length === 0;
        const noMoreLabels = labels.length === 0;

        return (
            <ComponentContainer>
                <ChartContainer>
                    <Chart labels={labels} type="bar" maintainAspectRatio={false} showStacked={false} disableAnimations={true}
                           style={{height: '70%'}}>
                        {this.renderDatasets()}
                    </Chart>
                </ChartContainer>
            </ComponentContainer>
        );
    }
}
BarChart.propTypes = {
    type: PropTypes.string,
};
BarChart.defaultProps = {
    type: '',
};

const ComponentContainer = styled.div`
    height: 100%;
    width: 100%;
`;


const ChartContainer = styled.div`
  height: 100%;
  display: flex;
  align-items:center;
`;


export default BarChart;