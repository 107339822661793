import React from "react";
import PropTypes from "prop-types";
import {Tab, Tabset, ButtonGroup, ButtonIcon, Button} from "react-rainbow-components";
import styled from "@emotion/styled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


// Components
import BarChart from "./BarChart";
import TableList from "./TableList";


class Detail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: 'graph'
        };

        this.handleOnSelect = this.handleOnSelect.bind(this);
    }

    handleOnSelect(event, selected) {
        this.setState({selected});
    }

    getTabContent() {
        const {selected} = this.state;

        if (selected === 'list') {
            return (
                <TabBody aria-labelledby="list" id="listTab">
                    <TableList type={this.props.type}/>
                </TabBody>
            );
        }

        return (
            <TabBody aria-labelledby="graph" id="graphTab">
                <BarChart type={this.props.type}/>
            </TabBody>
        );
    }

    render() {
        const {selected} = this.state;

        let bgColor;
        switch (this.props.type) {
            case 'electricity':
                bgColor = "#C8E6FF";
                break;
            case 'solarPower':
                bgColor = "#FFE299";
                break;
            default:
                bgColor = "#eeeeee";
        }

        return (
            <Tabs>
                <TabsInnerContainer className="rainbow-flex rainbow-flex_column rainbow_vertical-stretch">
                    <Tabset
                        //fullWidth
                        onSelect={this.handleOnSelect}
                        activeTabName={selected}
                        className="rainbow-p-horizontal_x-large rainbow-p-top_large"
                        style={{backgroundColor: bgColor}}
                    >
                        <Tab
                            name="graph"
                            label={<span><FontAwesomeIcon icon="chart-bar"/> グラフ</span>}/>
                        <Tab
                            name="list"
                            label={<span><FontAwesomeIcon icon="th-list"/> リスト</span>}/>
                    </Tabset>
                    {this.getTabContent()}
                </TabsInnerContainer>
            </Tabs>
        );
    }
}

Detail.propTypes = {
    type: PropTypes.string,
};

Detail.defaultTypes = {
    type: '',
};


const Tabs = styled.div`
    height: 100%;
    width: 100%;
    background-color: white;
`;


const TabsInnerContainer = styled.div`
    height: 100%;
    width: 100%;
`;


const TabBody = styled.div`
    height: 100%;
    width: 100%;
    padding: 40px;
`;


export default Detail;