import React, {Component} from 'react';
import ReactFullpage from '@fullpage/react-fullpage';


const fullpageOptions = {
    anchors: ['electricity', 'solarpower'],
    scrollingSpeed: 1000,
    //paddingTop: '80px',
    onLeave: (origin, destination, direction) => {

        if (typeof window !== "undefined") {
            const menuElement = document.getElementsByClassName('menu-list');

            if (menuElement.length > 0) {
                const element = menuElement[0].querySelectorAll('[data-anchor]');

                if (element.length > 0) {
                    // スタイルクラスをすべて削除する
                    for (let i = 0; element.length > i; i++) {
                        element[i].classList.remove('active');
                    }

                    // アクティブなボタンにスタイルクラスを追加する
                    for (let i = 0; element.length > i; i++) {
                        if (element[i].dataset.anchor === `#${destination.anchor}`) {
                            element[i].classList.add('active');
                        }
                    }
                }
            }
        }
    }
};

class Fullpage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ReactFullpage
                {...fullpageOptions}
                render={({state, fullpageApi}) => {
                    return (
                        <ReactFullpage.Wrapper>
                            {this.props.children}
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        )
    }
}


export default Fullpage;