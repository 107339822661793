import React from "react";
import PropTypes from "prop-types";
import {Spring} from "react-spring/renderprops";
import styled from "@emotion/styled";


const Information = (props) => {
    let bgColor;
    let label;
    let value;

    switch (props.type) {
        case 'electricity':
            label = "家全体";
            bgColor = "#C8E6FF";
            value = 17.6;
            break;
        case 'solarPower':
            label = "太陽光発電";
            bgColor = "#FFE299";
            value = 3.1;
            break;
        default:
            label = "その他の系統";
            bgColor = "#eeeeee";
    }

    return (
        <div className="information" style={{...props.style, backgroundColor: bgColor}}>
            <div className="information__grid">
                <InformationLabel className="information__label">{label}</InformationLabel>
                <InformationValue className="information__value">{value}</InformationValue>
                <InformationUnit className="information__unit">kWh</InformationUnit>
                <InformationRange className="information__range">（今日）</InformationRange>
            </div>
        </div>
    )
};

Information.propTypes = {
    type: PropTypes.string,
};

Information.defaultProps = {
    type: '',
    label: "系統",
    value: 0.0,
};


const InformationLabel = styled.div`

`;

const InformationValue = styled.div`
    font-family: a-otf-ud-shin-go-pr6n, sans-serif;
    font-weight: 300;
`;

const InformationUnit = styled.div`

`;

const InformationRange = styled.div`

`;


export default Information;