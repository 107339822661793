import React from 'react';
import styled from "@emotion/styled";
import {Button, ButtonGroup, Dataset, Table, Column} from 'react-rainbow-components';


// MockData
import {electricityDatasets} from "../../data/electricity";


class TableList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datasets: electricityDatasets,
        };
    }

    componentDidMount() {
        this.convertDatasets();
    }

    convertDatasets() {
        let convertData = [];

        for (let i = 0; i < this.state.datasets.length; i++) {
            let colData = {
                title: this.state.datasets[i].title,
                id: Math.random().toString(32).substring(2)
            };
            for (let k = 0; k < 24; k++) {
               colData[`t${k}`] = this.state.datasets[i].values[k];
            }
            convertData.push(colData);
        }
        return convertData;
    }

    render() {
        return (
            <ComponentContainer>
                <ChartContainer>
                    <Table
                        keyField="id"
                        data={this.convertDatasets()}
                        maxColumnWidth={5000}
                        minColumnWidth={200}
                    >
                        <Column header="ラベル" field="title"/>
                        <Column header="0時" field="t0"/>
                        <Column header="1時" field="t1"/>
                        <Column header="2時" field="t2"/>
                        <Column header="3時" field="t3"/>
                        <Column header="4時" field="t4"/>
                        <Column header="5時" field="t5"/>
                        <Column header="6時" field="t6"/>
                        <Column header="7時" field="t7"/>
                        <Column header="8時" field="t8"/>
                        <Column header="9時" field="t9"/>
                        <Column header="10時" field="t10"/>
                        <Column header="11時" field="t11"/>
                        <Column header="12時" field="t12"/>
                        <Column header="13時" field="t13"/>
                        <Column header="14時" field="t14"/>
                        <Column header="15時" field="t15"/>
                        <Column header="16時" field="t16"/>
                        <Column header="17時" field="t17"/>
                        <Column header="18時" field="t18"/>
                        <Column header="19時" field="t19"/>
                        <Column header="20時" field="t20"/>
                        <Column header="21時" field="t21"/>
                        <Column header="22時" field="t22"/>
                        <Column header="23時" field="t23"/>
                    </Table>
                </ChartContainer>
            </ComponentContainer>
        );
    }
}


const ComponentContainer = styled.div`
    height: 100%;
    width: 100%;
`;


const ChartContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: scroll;
`;


export default TableList;