import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


/**
 * 系統のアイコン
 * @param props.type {string} - 系統の種類
 * @returns {*}
 * @constructor
 */
const SystemIcon = (props) => {
    let fontColor;
    let bgColor;

    switch (props.type) {
        case 'electricity':
            fontColor = '#0073A0';
            bgColor = '#C8E6FF';
            break;
        case 'solarPower':
            fontColor = '#FF7832';
            bgColor = '#FFE299';
            break;
        default:
            fontColor = '#333333';
            bgColor = '#ffffff';
    }


    return (
        <ComponentContainer
            className="system-icon"
            propFontColor={fontColor}
            propBgColor={bgColor}
        >
            <Icon type={props.type} color={fontColor}/>
        </ComponentContainer>
    )
};
SystemIcon.propTypes = {
    type: PropTypes.string,
};
SystemIcon.defaultProps = {
    type: '',
};


const Icon = (props) => {
    switch (props.type) {
        case 'electricity':
            return (
                <FontAwesomeIcon icon="plug" color={props.color}/>
            );
        case 'solarPower':
            return (
                <FontAwesomeIcon icon="sun" color={props.color}/>
            );
        default:
            return (
                <FontAwesomeIcon icon="plug" color={props.color}/>
            );
    }
};
Icon.propTypes = {
    type: PropTypes.string,
    color: PropTypes.string,
};
Icon.defaultProps = {
    type: '',
    color: '#333333',
};


const ComponentContainer = styled.div`
    color: ${props => props.fontColor}; 
    background-color: ${props => props.propBgColor};
`;


export default SystemIcon;