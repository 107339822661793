export const powerGeneration = [
    {
        title: "太陽光発電",
        backgroundColor: '#FF7832',
        values: [
            0.0, 0.0, 0.0, 0.0, 0.3, 0.7, 1.3, 1.4, 1.9, 2.1, 2.3, 2.8,
            3.2, 3.0, 2.7, 1.2, 0.2, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0
        ],
    },
    {
        title: "その他の発電",
        backgroundColor: '#9c2156',
        values: [
            0.4, 0.5, 0.4, 0, 0, 0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0
        ],
    },
];