import React from "react";
import PropTypes from "prop-types";
import {Button, Breadcrumbs, Breadcrumb} from "react-rainbow-components";
import {Spring} from "react-spring/renderprops";
import styled from "@emotion/styled";
import {Textfit} from "react-textfit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


// Components
import settings from "../../settings";
import SystemIcon from "../SystemIcon";
import DashbordButton from "./DashbordButton";
import Detail from "./Detail";
import SubMenu from "./SubMenu";
import Information from "./Information";


/**
 * Dashbord component
 */
class Dashbord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDisplayDetail: false,
        };
    }

    toggleDisplayDetail() {
        this.setState({isDisplayDetail: !this.state.isDisplayDetail});
    }

    openDisplayDetail() {
        if (!this.state.isDisplayDetail) {
            this.setState({isDisplayDetail: true});
        }
    }

    closeDisplayDetail() {
        if (this.state.isDisplayDetail) {
            this.setState({isDisplayDetail: false});
        }
    }

    render() {
        return (
            <div className="dashbord">
                <Spring
                    from={{
                        width: this.state.isDisplayDetail ? '0%' : '100%',
                        opacity: this.state.isDisplayDetail ? '0' : '1',
                    }}
                    to={{
                        width: this.state.isDisplayDetail ? '100%' : '0%',
                        opacity: this.state.isDisplayDetail ? '1' : '0',
                    }}
                >
                    {props => (
                        <div className="detail" style={props}>
                            <Detail type={this.props.type}/>
                        </div>
                    )}
                </Spring>

                <SubMenu type={this.props.type}>
                    <DashbordButton
                        height="200px"
                        isDisplayDetail={this.state.isDisplayDetail}
                        onClickOpenFunc={this.openDisplayDetail.bind(this)}
                        onClickCloseFunc={this.closeDisplayDetail.bind(this)}
                        style={{marginTop: '2rem'}}
                    />
                </SubMenu>

                <Spring
                    from={{
                        width: this.state.isDisplayDetail ? '100%' : '0%',
                        opacity: this.state.isDisplayDetail ? '1' : '0',
                    }}
                    to={{
                        width: this.state.isDisplayDetail ? '0%' : '100%',
                        opacity: this.state.isDisplayDetail ? '0' : '1',
                    }}
                >
                    {props => (
                       <Information type={this.props.type} style={props}/>
                    )}
                </Spring>

            </div>
        )
    }
}

Dashbord.propTypes = {
    type: PropTypes.string,
};

Dashbord.defaultProps = {
    type: '',
};


export default Dashbord;