export const electricityDatasets = [
    {
        title: "家全体",
        backgroundColor: '#0073A0',
        values: [
            0.1, 0.0, 0.0, 0.0, 0.0, 0.0, 0.8, 1.8, 0.9, 0.6, 0.8, 0.8,
            0.2, 0.3, 0.5, 0.6, 1.2, 1.8, 1.9, 1.8, 1.5, 1.2, 0.7, 0.1
        ],
    },
    {
        title: "リビング",
        backgroundColor: '#ff6837',
        values: [
            0, 0, 0, 0, 0, 0, 0.8, 1.8, 0.9, 0.6, 0.8, 0.8,
            0.2, 0.3, 0.2, 0.4, 1.2, 1.8, 1.6, 1.5, 1.2, 0.8, 0.4, 0
        ],
    },
    {
        title: "寝室",
        backgroundColor: '#ffcc00',
        values: [
            0.1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2, 0.3, 0.1
        ],
    },
    {
        title: "こども部屋",
        backgroundColor: '#1ad1a3',
        values: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0.3, 0.2, 0, 0, 0.3, 0.3, 0.3, 0.2, 0, 0
        ],
    },
];