import React from "react";
import PropTypes from "prop-types";


// Components
import SystemIcon from "../SystemIcon";


const SubMenu = (props) => {
    let label;
    let bgColor;

    switch (props.type) {
        case 'electricity':
            label = "電気";
            bgColor = "#0073A0";
            break;
        case 'solarPower':
            label = "太陽光";
            bgColor = "#FF7832";
            break;
        default:
            label = "その他";
            bgColor = "#333333";
    }
    return(
        <div className="subMenu" style={{backgroundColor: bgColor, ...props.style}}>
            <div className="subMenu__grid">
                <div className="subMenu__cell1">
                    <SystemIcon type={props.type}/>
                    <p className="system-icon-label">{label}</p>
                </div>
                <div className="subMenu__cell2">
                    {props.children}
                </div>
            </div>
        </div>
    )
};

SubMenu.propTypes = {
    type: PropTypes.string,
};

SubMenu.defaultProps = {
    type: '',
};

export default SubMenu;