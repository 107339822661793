const settings = {
    breakpoints: {
        small: '0',
        medium: '640px',
        large: '1024px',
        xlarge: '1200px',
        xxlarge: '1440px',
    }
};


export default settings;