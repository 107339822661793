import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';


// Components
import Typekit from './components/Typekit';
import App from './App';


const RenderElement = () => {
    return (
        <div>
            <Typekit kitId="ptq5qye"/>
            <App/>
        </div>
    )
};

ReactDOM.render(<RenderElement/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
