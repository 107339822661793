import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const DashbordButton = (props) => {
    const onClick = (event) => {
        event.preventDefault();

        if (props.isDisplayDetail) {
            return props.onClickCloseFunc();
        } else {
            return props.onClickOpenFunc();
        }
    };

    const style = {
        borderRadius: props.circle ? '500px' : '20px',
        ...props.style
    };
    const icon = props.isDisplayDetail ? 'home-lg-alt' : 'chart-bar';

    return (
        <ComponentContainer
            href={props.href}
            onClick={(e) => onClick(e)}
            style={style}
            data-anchor={props.href}
            height={props.height}
            width={props.width}
            bgColor={props.bgColor}
            textColor={props.textColor}
            bgColorHover={props.bgColorHover}
            textColorHover={props.textColorHover}
            dropShadow={props.dropShadow}
        >
            <FontAwesomeIcon icon={icon}/>
        </ComponentContainer>
    )
};

DashbordButton.propTypes = {
    href: PropTypes.string,
    isDisplayDetail: PropTypes.bool.isRequired,
    onClickOpenFunc: PropTypes.func.isRequired,
    onClickCloseFunc: PropTypes.func.isRequired,
    height: PropTypes.string,
    width: PropTypes.string,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    bgColorHover: PropTypes.string,
    textColorHover: PropTypes.string,
    dropShadow: PropTypes.bool,
    circle: PropTypes.bool,
};

DashbordButton.defaultProps = {
    href: '#',
    height: '100%',
    width: '100%',
    bgColor: '#ffffff',
    textColor: '#333333',
    bgColorHover: '#00b7f5',
    textColorHover: '#ffffff',
    dropShadow: true,
    circle: false,
};


const ComponentContainer = styled.a`
    height: ${props => props.height};
    width: ${props => props.width};

    font-size: 4rem;
    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    ${props => props.dropShadow && `
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    `};

    &:link,
    &:visited,
    &:focus,
    &:hover {
        color: ${props => props.textColor};
        background-color: ${props => props.bgColor}; 
    }
    /*
    &:hover { 
        color: ${props => props.textColorHover};
        background-color: ${props => props.bgColorHover};
    }
    */
    
    &:active {
        ${props => props.dropShadow && `
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
        `};
    }
`;

export default DashbordButton;